<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Games</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <div id="container">
        <div class="info">
            Select a game to start your practice session. Your results will be tracked so you can compare to future sessions.
        </div>
        <GamesList :games="global.games.state.games" />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonButtons, 
  IonContent, 
  IonHeader, 
  IonMenuButton, 
  IonPage, 
  IonTitle, 
  IonToolbar,
} from '@ionic/vue';

import GamesList from '@/components/games/GamesList';

export default {
  name: 'GamesHome',
  inject: ['global'],
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,

    GamesList,
  },

  setup() {
    return {};
  },

  created() {
  },

  data() {
    return {
    };
  },

  methods: {
  },
}
</script>

<style scoped>
  .info {
    margin: 2rem 2rem 1rem;
    font-weight: bold;
    text-align: center;
  }
</style>