<template>
  <ion-list lines="full">
    <ion-item
      v-for="game in games"
      :key="game.id"
      :router-link="{ name: 'GameDetails', params: { id: game.id } }"
    >
      <ion-icon item-left :ios="golfSharp" :md="golfSharp"></ion-icon>
      <ion-label>
        <div class="title-wrap">
          <h2>{{ game.title }}</h2>
          <!-- <GameTrend :game="game" /> -->
        </div>
        <p>{{ game.description }}</p>
        <p>
          <ion-badge v-for="(area, index) in game.areas" :key="index" :color="getColour(area)">{{ area }}</ion-badge>
        </p>
      </ion-label>
    </ion-item>
  </ion-list>
</template>

<script>
import {
  IonList, 
  IonItem,
  IonBadge,
  IonLabel,
  IonIcon,
} from '@ionic/vue';
import { golfSharp } from 'ionicons/icons';
// import GameTrend from '@/components/games/charts/GameTrend';

export default {
  name: 'GamesList',
  inject: ['global'],
  components: {
    IonList,
    IonItem,
    IonBadge,
    IonLabel,
    IonIcon,

    // GameTrend,
  },

  props: {
    games: {
      type: Array,
      default: () => [],
    },
  },

  setup() {
    return {
      golfSharp,
    };
  },

  created() {
  },

  data() {
    return {
      colours: [
        'primary',
        'secondary',
        'tertiary',
        'success',
        'warning',
        'danger',
      ].sort(),

      colourAssign: {},
    };
  },

  methods: {
    getColour(val) {
      const colour = this.colourAssign[val] || this.colours[Object.keys(this.colourAssign).length] || this.colours[0];

      this.colourAssign[val] = colour;

      return colour;
    },
  },
}
</script>

<style scoped lang="scss">
  ion-icon {
    margin-right: 1rem;
  }

  ion-badge {
    margin-right: 0.5rem;
  }

  .title-wrap {
    display: flex;

    h2 {
      margin-right: 1rem;
    }
  }
</style>